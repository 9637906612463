html{
    scroll-behavior: smooth;
}

.rect{
background-color:  #7D62E8;   
padding-top: 25px;
padding-bottom: 25px;
width: 100%; 
margin-top: -10px;
}
.sec1{
    padding-left:50px ;
    padding-right: 50px;
    background-image: url(Img/slope1.svg);
    background-color: #7D62E8;
    background-position: top;
    background-size: cover;
    height: auto;
    position: relative;
    padding-top: 80px;
    padding-bottom: 100px;
}
.title1{
    font-family: Lato;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 100px;
    letter-spacing: 2px;
    text-align: left;    
    color: #7D62E8;
    
}
.text1{
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: 2px;
    text-align: left;    
    color: #7D62E8;
       
}
.btn-2{
    display: none;
}
.btn1{
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;    
    background: #7D62E8;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;    
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 50px;
}
.sec2{
    padding-top: 100px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 100px;
}
.text2{
    font-family: Nunito Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 3px;
    text-align: left;
    color:#7D62E8;
   
}
.title2{
    font-family: Lato;
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 77px;
    letter-spacing: 3px;
    text-align: left;    
    color: #6C208F;
}
.text3{
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 3px;
    text-align: left;    
    color: #7d62e8;    
}
.btn2{
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
   padding-top: 19px;
   padding-bottom: 19px;    
   background: #7d62e8;
   padding-left: 41px;
   padding-right: 41px;
}
.sec3{
    background-color: #7D62E8;
    background-image: url(Img/slope2.svg);
    background-repeat: no-repeat;
    width: 100%;
    background-position: bottom;
    padding-bottom: 100px;
    padding-top: 50px;
}
.sec2 .col-lg-5{
    padding: 0px 0px 0px 0px;
    align-content: left;
    justify-content: left;
}
.text4{
    font-family: Lato;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 3px;
    text-align: center;
      color: white;  
}
.sub-sec-3{
    padding-left: 50px;
    padding-right: 50px;
}
 .sub-sec-3{
    padding-top: 50px;
}
.sub-sec-3 .card{
     padding-top: 20px;
     position: relative;
     padding-left: 24px ;
     padding-right: 24px;
    
}
.sub-sec-3 .one{
    background-image: url(Img/bg-sec-1.png) !important;
    background-repeat: no-repeat;
    background-position:left bottom;
}
.sub-sec-3 .two{
    background-image: url(Img/bg-sec-2.png);
    background-repeat: no-repeat;
    background-position: top right;
}
.sub-sec-3 .three{
    background-image: url(Img/bg-sec-3.png) !important;
    background-repeat: no-repeat;
    background-position:top right;
}
.sub-sec-3 .card-title{
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 3px;
    text-align: center;
    color: #7D62E8;
}
.sub-sec-3 .card-text{
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 3px;
    text-align: center;
    color: #9D9CB5;
}
.learn-more{
    background: #7D62E8;
    border-radius: 15px;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
          
}
.sec4{
    background-color: #FFFFFF;
    background-image: url(Img/slope4.svg);
    background-repeat: no-repeat;
    width: 100%;
    background-position: bottom;

 
}
.bg-img{
    background-image: url(Img/slope3.svg);
    background-repeat: no-repeat;
    width: 100%;
    background-position: bottom;
    padding-bottom: 100px;
        padding-left: 40px;
        padding-top: 50px;
}
.text5{
    font-family: Lato;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 3px;
    text-align: center;
    color: #7D62E8;
}
.title3{
    font-family: Lato;
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 77px;
    letter-spacing: 3px;
    text-align: left;
    color: #6C208F;
}
.text6{
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 3px;
    text-align: left;
    color: #7D62E8;    
}
.sec5{
    padding: 50px;
    background: #7D62E8;
}
.text7{
    font-family: Lato;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 3px;
    color: #FFFFFF; 
}
.sub-sec-4 input{
   padding-bottom: 28px;
    padding-top: 28px;
    padding-left: 38px;
}
.sub-sec-4 textarea{
    height: 171px;
    resize: none !important;
}
.sub-sec-4 ::placeholder {
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 3px;
    text-align: left;
    color: #7D62E8;      

}
.ota{
    display: none;
}
.sec6{
    padding-left: 50px;
    padding-right: 50px;
}
.submit-btn{
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    color: #7D62E8;      
    background: white;   
    padding: 19px 153px;
    margin-top: 47px;
}
.sec6 .card{
    background: #7D62E8;
    border-radius: 15px;
    padding-top: 22px;    
    padding-bottom: 22px;
}
.text8{
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;   
    color: #FFFFFF;
    
}
.text9{
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 3px;
    text-align: left;
    color: #6C208F;    
}
.text10{
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 3px;
    
    color: #FFFFFF;
    
}
.sec7{
    padding-top: 50px;
}
.title4{
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 72px;
    letter-spacing: 3px;
    text-align: center;
    color: #7D62E8;
    text-transform: uppercase;
}
.sec7{
    padding-left: 160px;
    padding-right: 160px;
}
.landing-footer{
    background-color: #7D62E8;
   padding-top: 40px;
   padding-bottom: 60px;
   padding-left: 20px;
   padding-right: 20px;
}
.footer-img{
    background-image: url(Img/footer.svg);
    padding-bottom: 80px;
    background-position: left bottom;
    background-repeat: no-repeat;

}
.footer-title{
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    color: white;       
}
.list-unstyled li{
    padding-bottom: 19px;
}
.list-unstyled a{
    text-decoration: none;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: white;    
}
.search-input{
    background: #FFFBFB;
border-top-left-radius: 15px;
border-bottom-left-radius: 15px;
border-top-right-radius: 0px;
border-bottom-right-radius: 0px;
outline: none !important;
border: white;
padding-left: 20px;
padding-bottom: 11px;
padding-top: 11px;
}
.search-input::placeholder{
font-family: Nunito Sans;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 22px;
letter-spacing: 0em;
text-align: left;

}

.search-btn{
    background: #6C208F;
border-top-right-radius: 15px;
border-bottom-right-radius: 15px;
border-top-left-radius: 0px;
border-bottom-left-radius: 0px;

font-family: Nunito Sans;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
color: white;
padding-top: 14.3px;
padding-bottom: 14.4px;
padding-left: 16px;
padding-right: 20px;
}
.testimonial{
    margin-right: 10px;
}
.rec-pagination button{
 display: none;
}
.rec-arrow-right, .rec-arrow-left{
    color:#7D62E8 !important;
    background: none !important;

}
.land1{
    position: relative;

}
.land2{
    position: relative;
}
.heart1{
    position: absolute;
    top: 0px;
    left: 74px;
}
.heart2{
    position: absolute;
    top: 70px;
    right:20px;
}
.heart3{
    position: absolute;
    left: 0px;
    bottom: 0px;
}
.heart4{
    position: absolute;
    left:50px;
    bottom: 70px;
}
.heart5{
    position: absolute;
    bottom: 60px;
   left: 950px;
}
.heart6{
    position: absolute;
    right: 30px;
    top: 180px;
}
.heart7{
    position: absolute;
    bottom: 80px;
    right: 30px;
}
.test-card{
    background: #7D62E8;
    border-radius: 15px;
   padding-top: 30px;
   padding-bottom: 18px;     
   padding-left: 21px;
   padding-right: 21px;  
   margin-right: 28px;
}
.test-content{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    align-items: initial;
    text-align: left;
}
.test-text{
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
}
.rec.rec-arrow {
    border-radius: 0;
    border: 1px solid transparent !important;
    box-shadow: none !important;
}

.list-unstyled li a{
    position: relative;
    display: inline-block;   
}
.list-unstyled li a::after{
    content: "";
    position: absolute;
    top: 100%;
    height: 2px;
    width: 41.5px;
    left: 5%;
    background-color:white;    
    transition: 0.4s ease-out all .1s;
}
@media only screen and (max-width: 769px) and (min-width: 768px){
    .list-unstyled li a::after{
        background-color: transparent !important;
    } 
}

.contact-img{
  border-radius: 25px;
}
.footer-slope{
    margin: 0px;
}