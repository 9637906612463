.my-navbar{
  position: relative;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background:  #7D62E8 ;    
  padding-left: 50px;
  padding-right: 50px;

  position: fixed;
  width: 100%;
  z-index: 1020;
}

.my-nav{
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-pack: center !important;
  justify-content: center ;
  margin-top: 15px;
}
.land-nav .my-nav-list{
  padding-left: 0;
  list-style: none;    
}
.my-nav-item{
  padding-left: 20px;
  padding-right: 5px;

}
.my-navbar a{
  text-decoration: none;
  color: white;
}
.my-nav-link{
  position: relative;
  display: inline-block;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  
}
.my-nav-link::after{
  content: "";
  position: absolute;
  top: 110%;
  height: 2px;
  width: 41.5px;
  left: 10%;
  background-color: #FFFCFC;  
  transition: 0.4s ease-out all .1s;
}
.my-nav-item:hover .my-nav-link::after{
  width: 100%;
  left: 0;
    transition: 0.5s ease all; 
}
@media screen and (min-width: 992px){
  .my-nav-icon{
      display: none;
  }
 .land-nav .my-nav-list{
      display: flex;
  }
}
@media screen and (max-width: 992px){
  .my-nav-icon{
      display: inline-block;
      margin-left: auto;
   
  }
  .my-nav-icon{
      background: none;
      outline: none;
      border: none;
     
  }
  .my-nav{
      display: flex;
      width: 100%;
      height: 0;
      opacity: 0;
      transition: height  0.5s;
  }
  .my-nav.active{
      left: 0;
      opacity: 1;
      z-index: 1;
      align-content: center;
      padding-left: 0px;
      height: 300px;
      transition:  height 0.5s;
  }
.land-nav  .my-nav-list{
      display: block;
      text-align: center;
   margin-bottom: 0px;
   margin-top: 10px;
  }
  .my-nav-item{
    margin-bottom: 20px;  

  }
  

}
/* Dropdown Button */
.dropbtn {
  color: white;
  border: none;
  background: none
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  right: 0px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color:  #7D62E8 ;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #7D62E8; color: white;}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {background-color: #7D62E8 ;}
@media screen and (max-width: 992px){
  .my-navbar{
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 15px;
    background: #7D62E8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   border-bottom-left-radius: 8px;
   border-bottom-right-radius: 8px;
    
  }
}
.my-nav-link{

}