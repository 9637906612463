.hospital-info {
    display: flex;
    margin-bottom: 3rem;

}
.hospital img {
    margin:0 1rem 0 2rem;
}
.hospital-info-split {
    display: flex;
    align-items: center;
}
.hospital-info-text {
    text-align: left;
}
.hospital-info-text h5 {
    font-family: Lato;

}
.hospital-desc-location p {
    font-family: poppins;
}
.appointment-button {
    justify-content: center;
}
.hosp-button {
    border-radius: 70px;
    background-color: #7D62E8;
    width: 100%;
    height: 60%;
    padding: 0 4rem;
    margin-left: 3rem;
}
hr{
    background-color: #7D62E8;
    width: 100%;
}


/* mobile responsiveness */
@media screen and (max-width: 1024px){
    .hosp-button {
        padding: 0 2.5rem;
        margin-left: 2rem;
    }
}
@media screen and (max-width: 600px){
    .hosp-button {
        padding: 0 1.6rem;
    margin-left: 1.5rem;
    }
}
@media screen and (max-width: 500px){
    .hosp-button {
        padding: 0 1rem;
        margin-left: 0.5rem;
    }
    .hospital-image {
        height: 70px;
        width:150px;
    }
}
@media screen and (max-width: 400px){
    .hosp-button {
        padding: 0 0.3rem;
        margin-left: 0.1rem;
        font-size: 9px;
    }
    .hospital-info-text h5 {
        font-size: 14px;
    }
    .hospital-info-text p {
        font-size: 10px;
    }
}