.notification-cards{
    padding-left: 51px;    
    padding-right: 50px;
 
}
.notification-card{
    border-radius: 0px;
    border:none;
    border-bottom: 1px solid #7D62E8;
    border-top: 1px solid #7D62E8;
}

.notification-image{
    max-width: 100%;
    height: auto;
}
.notification-content{
    padding-top: 10px;
    padding-left: 20px;
    color: #7D62E8;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: left;
        
}
.mention-author{
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  
}
.personal-mention{
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
        color: #6C208F;
}
@media screen and (max-width: 768px){
   .notification-cards{
       padding-left: 26px;
       padding-right: 26px;
   }
   .notification-content{
       padding-right: 0px;
   }

}
@media screen and (max-width: 576px){
    .notification-image img{
        width: 50px;
        height: 50px;
    }
    .notification-content{
        padding-top: 0px;
        line-height: 0px;
        font-size: 16px;
        padding-right: 0px;
        padding-left: 14px;
    }
    .mention-author{
        font-size: 18px;
    }
    .reply{
        font-size: 18px;
        padding-top: 15px;
        line-height: 19px;
    }
    .personal-mention{
        font-weight: normal;
        font-size: 16px;      
    }
}