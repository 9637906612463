.sub-title p{
    font-family: Lato;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    
}
.post-card{
    padding-left: 51px;
    padding-right: 45px;
}
.post-content-card{
    border: none;
    border-top: 2px solid #7D62E8;
    border-radius: 0px;
    padding-bottom: 50px;
}
.post-author-img{
   max-width: 100%;
    padding-top: 11px;
}
.post-detail-body{
    padding-top: 10px;
}
.post-author-name{
    font-family: Nunito Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #7D62E8;    
}
.post-content-title{
    font-family: Nunito Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
   color: #7D62E8;    
}
.post-main-body{
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #7D62E8;    
    padding-top: 20px;
}
.like-post, .repost, .comment{
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #7D62E8;    
}
.post-comment{
    border: 1px solid #7D62E8;
    box-sizing: border-box;
    border-radius: 8px;
    resize: none;   
}
.post-comment::placeholder{
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
   color: #7D62E8;    
}
.submit-comment{
    background: #7D62E8;
    border-radius: 3px;   
    color: white;
    margin-top: 10px;
    float: right;
}
.comments{
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #7D62E8;
}
.comment-image{
    max-width: 100%;
    height: auto;
}
.comment-content{
    line-height: 45px;
}
.comment-author{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 2px;
    
    color: #7D62E8;   
}
.comment-body{
    font-family: Lato;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    
    color: #7D62E8;
    
}
@media screen and (max-width: 768px){
    .post-card{
        padding-right: 26px;
        padding-left: 26px;
    }
}
@media screen and (max-width:576px){
    .comment-image img{
        width: 50px;
        height: 50px;
    }
    .post-detail-body{
        padding-top: 15px;
    }
    .post-author-name{
        font-size: 16px;
    }
    .post-author-img{
        max-width: 100%;
    }
    .post-author-img img{
        width: 40px;
        height: 40px;
    }
    .post-content-title{
        font-size: 18px;
    }
    .post-main-body{
        font-size: 14px;
        line-height: 25px;    
    }
    .like-post, .repost,.comment{
        font-size: 14px;
    }
    .comment-content{
        padding-top: 0px;
        line-height: 0px;
    }
    .comment-author{
        font-size: 16px;
    }
    .comment-body{
        padding-top: 10px;
    }
    .submit-comment{
        font-size: 12px;
        line-height: 18px;           
    }
    .sub-title p{
        margin-bottom: 0em;
    }
}