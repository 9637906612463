@media only screen and  (max-width: 1025px ){
.img1{
  margin-top: 30px;
}
.title1{
  font-size: 45px;
}
.text1{
  font-size: 28px;
}
.heart3{
  left: 50px;
}
.sec1{
  padding-left: 30px;
  padding-right: 30px;
 }
 .sec2{
  padding-left: 30px;
  padding-right: 30px;
 }
 .sub-sec-3 .card{
   height: 450px;
 }
 
 .text2{
   margin-bottom: 0.7rem !important;
 }
 .title2{
   font-size: 45px;
   line-height: 77px;
 }
 .sub-sec-3 .learn-more{
  position: absolute;
  z-index: 1000;
  bottom: 4px;
  right: 0.5px;
}
.bg-img{
  padding-left: 30px;
  padding-right: 30px !important;
}
.sec5{
  padding-left: 30px;
  padding-right: 30px;
}
.text7{
  text-align: center;
}
}

@media only screen and (min-width: 992px){

  .landing-nav-collapse{
    display: block !important;
  }
}
@media (min-width: 769px) {
  .list-unstyled li:hover a::after{
    width: 100%;
    left: 0;
      transition: 0.5s ease all; 
}
    .sub-sec-3{
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
      -webkit-column-gap: 1.25rem;
      -moz-column-gap: 1.25rem;
      column-gap: 1.25rem;
      orphans: 1;
      widows: 1;
    }
    .sub-sec-3 .card {
      display: inline-block;
     width: auto;
     position: relative;
   

    }

  
  }
  @media screen and (max-width: 992px){
    .heart5{
      display: none;
    }
    .my-nav-link::after{
      width: 0px !important;
    }
.sub-sec-4 input, .sub-sec-4 textarea, .sub-sec-4 button{
  border-radius: 15px;
}
.title3{
  padding-top: 15px;
}

.contact-image{
 width: 100%;
}
    .big button{
      background-color: white;
      width: 100%;
      text-align: center;
    }
    .heart1{
      top: -50px;
    }
    .submit-btn{
      padding: 19px 60px;
    }
    .ota{
      display: inline-block;
    }
    .sub-sec-2{
      padding-top: 20px;
    }
    .btn1{
      display: none;
    }
    .title2{
      font-family: Lato;
      font-size: 45px;
      font-style: normal;
      font-weight: 400;
      line-height: 67px;
      letter-spacing: 3px;
      text-align: left;
       
    }
    .img-align{
      width: 100%;
      position: relative;
      margin: auto;
    }
    .btn-2{
      font-family: Roboto;
      display: inline-block;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;    
    background: #7D62E8;
    border-radius: 10px;
    padding-top: 19px;
    padding-bottom: 19px;    
    padding-left: 65px;
    padding-right: 65px;
    margin-top: 50px; 
    }
    .land1{
      padding-bottom: 40px;
    }
    .heart6{
      top: 700px;
    }
    .heart7{
      bottom: 0px;
    }
    .heart2{
      right: 0px;
    }
    .sec1{
      padding-left: 30px;
      padding-right: 30px;
     }
     .title1, .title3{
       font-family: Lato;
       font-size: 45px;
       font-style: normal;
       font-weight: 400;
       line-height: 67px;
       letter-spacing: 2px;
       text-align: left;
             
     }   
     .sec6{
       padding-left: 0px;
       padding-right: 0px;
     }
     .text1{
      font-family: Poppins;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
      letter-spacing: 2px;
      text-align: left;
     }
     .img1{
       padding-top: 0px !important;
     }
     .sec2{
       padding-left: 30px;
       padding-right: 30px;
     }
     .text3{

        font-family: Poppins;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 50px;
        letter-spacing: 2px;
        text-align: left;
       
     }
     .bg-img{
       padding-left: 30px;
       padding-right: 30px ;
       position: relative;
     }
 
.about-img{
  display: none;
}
  }
  @media(min-width: 770px){
    .footer-md{
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
  }
  @media only screen and (max-width: 768px){
    .sub-sec-3 .learn-more{
      position: absolute;
      z-index: 1000;
      bottom: 0px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .list-unstyled li a::after{
      top: 150% !important;
      left: 50%;
  transform: translate(-50%, -50%);
    }

   .title2, .title3, .title4{
      font-family: Lato;
      font-size: 55px;
      font-style: normal;
      font-weight: 400;
      line-height: 77px;
      letter-spacing: 5px;

    }
    .title1{
      font-size: 64px;
      font-style: normal;
      font-weight: 400;
      line-height: 100px;
      letter-spacing: 2px;
    }
.text3, .text6{
      font-family: Poppins;
      font-size:  28px;
      font-style: normal;
      font-weight: 400;
      line-height: 50px;
      letter-spacing: 2px;
      text-align: left;
     padding-top: 20px;         
     text-align: left;
    }
    .btn2{
      margin-top: 5px;
    }
    .text1{
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 50px;
      letter-spacing: 2px;
    }
    .text2{
      padding-top: 20px;
    }
    .heart1{
      top: -50px;
    }
    .heart7{
      right: 60px;
    }
    .btn-2{
      float: right;
    }
    .test-card{
   display: block;
      width: 100%;
    }
    .sub-sec-3{
      padding-left: 30px;
      padding-right: 30px;
    }
    .sub-sec-3 .card{
      margin-bottom: 72px;
      width: auto;
      height: auto;
      padding: 30px;
    }
    .sub-sec-3 .card-title{
      font-size: 28px;
    }
    .sub-sec-3 .card-text{
      font-size: 20px;
    }
    .sub-sec-4 label{
      font-size: 28px;
    }
.test-card{
  margin-right: 0px;
}
  }
  @media screen and (max-width: 576px){
    .title1, .title2, .title3, .title4{
      font-size: 45px;
    }
    .text1, .text6, .text3{
      font-size: 24px;
    }
    .sub-sec-4 label{
      font-size: 24px;
    }
    .sub-sec-3 .card-title{
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px;
      letter-spacing: 3px;
   
     }
     .sub-sec-3 .card-text{
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px;
      letter-spacing: 3px;

     }
    .affliates{
      padding: 30px;

    }

    .sec7{
      padding-left: 60px;
      padding-right: 60px;
    
    }
    .test-card{
      padding-left: 11px;
      padding-right: 11px;
      padding-top: 10px;
      padding-bottom: 8px;
      margin-right: 0px;
    }
    .test-text{
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      min-width: 0;
      max-width: 100%; 
      padding-left: 12px !important;
    }
    .test-img{
      width: 57px;
      height: 57px
    }

  }
  @media screen and (max-width: 414px){
    .learn-more{
      text-align: center;
 
    }
    .learn-more .arrow{
      display: none !important;
    }
    .title1, .title2, .title3, .title4{
      font-size: 30px;
      line-height: 55px;
    }
    .text1, .text3, .text6{
      font-size: 18px;
    }
    .sub-sec-4 label{
      font-size: 18px;
    }
    .search-input{
      border-top-right-radius:  15px;
      border-bottom-right-radius: 15px;  
    }
    .search-btn{
      margin-top: 20px;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;  
    }
    .landing-footer{
      padding-left: 0px;
      padding-right: 0px;
    }
    .footer-img{
      background-image: url(Img/footer-mobile.svg);
      background-position: center;
    }
    .learn-more{
      text-align: center;
    }
    .learn-more img{
      display: none;
    }
    .sec1{
      background-image: url(Img/mobile-slope.svg);
      background-color: white;
     background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
    }
    .rect{
      display: none;
    }
    .land1{
      padding-bottom: 100px;
    }

    .title3{
      font-size: 30px;
      padding-top: 20px;
    }

    .btn{
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 23px;
      letter-spacing: 0em;
      text-align: left;
          
    }
 .text8{
  font-family: Nunito Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
    
 }
 .testimonial .col-4{
  -ms-flex: 0 0 30.333333%;
  flex: 0 0 30.333333%;
  max-width: 30.333333%;
 }
 .testimonial .col{
   padding-left: 2px !important;
 }
 .testimonial .col-4 img{
   width: 200%;
 }
 .heart4{
   bottom: 120px;
   left: 120px;
 }
 .heart2{
   top: 0px;

 }
 .heart7{
   right: 80px;
 }
 .btn1{
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  
 }
 .btn2{
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;

  
 }
 .btn2 .arrow{
   display: none;
 }
 .arrow{
   padding-left: 10px;
 }

  }