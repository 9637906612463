.mainbox{
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;    
}
.story{
    padding-top: 17px;
    padding-left: 27px;
    padding-right: 27px;
    padding-bottom: 20px;
   
}
.story-img{
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: #7D62E8;

}
.story-img img{
    width: 87px;
    height:89px;
}
.story-img p{
    padding-top: 20px;
}
.content-box{
    padding-left: 40px;
    padding-right: 40px;
}
.content-card{
    border-left: 0px;
    border-right: 0px;
    border-radius: 0px;
    border-bottom: 2px solid #7D62E8;
   /* border-top: 2px solid #7D62E8;*/
    padding-top: 11px;
    padding-bottom: 21px;
  
}
.main{
    position: relative;
}
.message-icon{
    position: fixed;
    right: 60px;
    padding-top: 15px;
    width: 86px;
    height: 81px;    
    bottom: 25px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    z-index: 1020;
   
}
.card-content{
    padding-top: 11px;
    padding-bottom: 11px;

}
.profile-img{
    border: 1px solid #7D62E8;
    border-radius: 50px;    
}
.post-details{
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;   
}
.author-details{
    position: relative;
    margin-left: -23px !important;
}
.break{
    padding-left: 35px;
}
.author-name{
    font-family: Nunito Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
   color: #7D62E8;       
   padding-bottom: 11px;
}
.aurhor-img{
    width: 60px;
    height: 60px;
    border: 1px solid #7D62E8;
}
.hashtag{
    color: #6C208F;
}
.post-title{
    padding-bottom: 11px;
    font-family: Nunito Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #7D62E8;       
}
.post-details{
    padding-left: 10px;
}
.post-content{
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    padding-top: 11px;
    color: #7D62E8;
       
}
.reaction{
    padding-top: 11px;
}
.like-post, .repost, .comment{
    cursor: pointer;
}
.message{
    fill: #7D62E8 !important;
    color: #7D62E8 !important;
}
.message-icon svg{
    width:56px;
    height: 49px;
}
@media screen and (max-width: 992px){
    .story{
        overflow-x: auto !important;
    }
.message-icon{
   display: none;
}
    .content-box{
        padding-left: 21px;
        padding-right: 21px;
    }
    .author-name{
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 25px;
        
        color: #7D62E8;
        
    }
    .post-title{
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        
        color: #7D62E8;
        
    }
    .post-content{
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        /* or 156% */
        
        
        color: #7D62E8;        
    }

    .content-box{
        margin-top: 0px;
    }
    .message-icon{
        background: #7D62E8;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
width: 50px;
height: 50px;
right: 28
px;
padding-top:5px;
    }
    .message-icon svg{
        width: 30.88px;
        height: 21.84px;
    }
    .message{
        fill: white !important;
        color: white !important;
    }
}
@media screen and (max-width: 768px){
    .resp{
        padding: 0px !important;
    }
  .mainbox{
      padding-bottom: 100px;
  }
    .break{
        padding-left: 0px;
    }
    .author-details{
        margin-left: 0px !important;
    }
    .content-box{
       padding: 0px 5px !important;
       padding-bottom: 50px !important;
    }
}