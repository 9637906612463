.bottom-nav{
    display: none;
}
@media screen and (max-width: 992px){
    .bottom-nav{
        position: fixed;
        bottom: 0px;
        z-index: 1020;
        background: #FFFFFF;
        border-top: 0.5px solid #7D62E8;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);               
        display: inline-block;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 100%;
        padding: 11px 11px;
    }
}