.sub-title{
    font-family: Lato;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 3px;
    text-align: left;
    padding-top: 21px;
    padding-left: 51px;            
    padding-bottom: 21px;
    color: #7D62E8;
}
.trend-img{
    position: relative;
    background: url(./bc.png) center center/cover no-repeat;
    height: 500px;
}
.trend-img-text{
    position: absolute;
    left: 52px;
    bottom: 70px;
    width: 386px;
}
.trend-author-name{
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 2px;
    text-align: left;
    color: #FFFFFF;    
}
.trend-time{
    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    
}
.trend-reason{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    text-align: left;    
    padding-top: 32px;    
}
.trend-cards{
    padding-top: 23px;
    border-top: 2px solid #7D62E8;
    padding-bottom: 100px;
}
.trend-card{
    border-bottom: 2px solid #7D62E8;
    border-radius: 0px;
 
}
.trend-card-body{
    padding: 16px 0px 16px 52px;
}
.trend-location{
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 27px;
    letter-spacing: 2px;
    text-align: left;
     color: #7D62E8;    
     margin: 0em;
}
.trend-name{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: 2px;
    padding-top: 13px;
    color: #7D62E8;
    padding-bottom: 13px;
       
}
.trend-mention{
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    
    letter-spacing: 2px;
    
    color: #7D62E8;
    
}
@media screen and (max-width: 992px){
    .sub-title{
        padding-top: 0px;
    }
    .trend-img{
        height: 300px;
    }
    .trend-img-text{
        left: 26px;
    }
}
@media screen and (max-width: 768px){
    .sub-title{
        padding-left: 26px;
    }
    .trend-img{
        height: 350px;
    }
    .trend-img-text{
        left: 26px;
        width: 290px;
        height: auto;
        bottom: 40px;
    }  
    .trend-author-name{
        font-size: 17px;
    }
    .trend-reason{
        font-size: 16px;
        padding-top: 19px;
    }
    .trend-card-body{
        padding: 16px 0px 16px 26px;
    }
    .trend-location{
        font-size: 16px;
    }
    .trend-name{
        font-size: 20px;
    }
    .trend-mention{
        font-size: 14px;
    }
}