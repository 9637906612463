.sidebox{
    background: #FFFFFF;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;    
    padding-top: 17px;
    padding-bottom: 17px;
    margin-bottom: 76px;
}
.side-title{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    color: #7D62E8;
    padding-left: 37px;
    padding-right: 37px;
    padding-bottom: 17px;  
    
}
.sidebox-content{
    padding-left: 37px;
    padding-right: 32px;
    padding-bottom: 17px;  
    padding-top: 25px;
    text-align: left;
}
.custom-check {
    display: block;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #7D62E8;
    margin-bottom: 20px;
  }
  
  /* Hide the browser's default checkbox */
  .custom-check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    right:0 ;
    background: #ccc;
    height: 18px;
    width: 18px;
    border-radius: 0px;
    
  }
  
  /* On mouse-over, add a grey background color */
  .custom-check:hover input ~ .checkmark {
    background-color: #6C208F;
  }
  
  /* When the checkbox is checked, add a blue background */
  .custom-check input:checked ~ .checkmark {
    background-color: #7D62E8;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .custom-check input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .custom-check .checkmark:after {
    left: 5px;
    top: 0;
    width: 7px;
    height: 13px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .appointment{
font-family: Lato;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
color: #7D62E8;  
 margin-bottom: 27px;
width: 100%;
  }
  .appointment .btn{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 10px;
   padding: 10px 16px;
    background: #7D62E8;
border-radius: 50px;
    color: #FFFFFF;
 float: right !important;
  }
  /* .appointment span{
   font-size: inherit;
  } */
  .user-details .name{
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: left;
    color: #7D62E8;       
  }
  .user-details .post{
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 1px;
    color: #7D62E8;
      
  }
.side-row{
  margin-bottom: 13px;
}
  @media screen and (max-width: 1024px){
    .side-title{
        padding-left: 12px;
        padding-right: 12px;
    }  
    .sidebox-content{
        padding-right: 12px;
        padding-left: 12px;
    }
  }