html,body {
  scrollbar-width: thin;          /* "auto" or "thin"  */
  scrollbar-color:   #ffffff #7D62E8 ;   /* scroll thumb & track */
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  margin-bottom: 5px; 
  margin-top: 2px; 
}

/* Track */
::-webkit-scrollbar-track {
  background: #7D62E8;
  box-shadow: inset 0 0 1px grey;
}

/* Track piece*/
::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 2px; 
}

::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:  white;
  border-radius: 10px;
}

/* Handle on hover */

.my-navbar.home-nav{
    display: flex;
    
    position: fixed;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background:  #7D62E8 ;    
    padding-left: 60px;
    padding-right: 60px;
  top: 0px;
    width: 100%;
    z-index: 1020;
    padding-top:0.5rem;
  }
  
 .home-nav .my-nav{
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    -ms-flex-pack: center !important;
    justify-content: center ;
  
  }
  .my-nav-list{
    padding-left: 0;
    list-style: none;    
  }
  .my-nav-item{
    padding-left: 20px;
    padding-right: 5px;
  
  }
  .my-navbar a{
    text-decoration: none;
    color: white;
  }
  .my-nav-link{
    position: relative;
    display: inline-block;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    
  }
  .my-nav-link::after{
    content: "";
    position: absolute;
    top: 110%;
    height: 2px;
    width: 41.5px;
    left: 10%;
    background-color: #FFFCFC;  
    transition: 0.4s ease-out all .1s;
  }
  .my-nav-item:hover .my-nav-link::after{
    width: 100%;
    left: 0;
      transition: 0.5s ease all; 
  }
  .home-nav .my-nav-list{
    display: flex !important;
  }
  @media screen and (min-width: 992px){
    .my-nav-icon{
        display: none;
    }

  }
  @media screen and (max-width: 992px){
    .home-nav .my-nav-list{
      margin-top: -11px !important;
    }
    .my-nav-icon{
        display: inline-block;
        margin-left: auto;
     
    }
    .my-nav-icon{
        background: none;
        outline: none;
        border: none;
       
    }
   .home-nav.my-nav{
        display: flex;
        width: 100%;
        height: auto;
        transition: height  0.5s;
    }

  .home-nav  .my-nav-item{
      margin-bottom: 0px;  
  
    }
    
  
  }
  /* Dropdown Button */
  .dropbtn {
    color: white;
    border: none;
    background: none
  }
  
  /* The container <div> - needed to position the dropdown content */
   .home-nav .dropdown {
    position: relative;
    display: inline-block;

    
  }
  
  /* Dropdown Content (Hidden by Default) */
.home-nav  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 232px;
    right: 0px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 8px;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color:  #7D62E8 ;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #7D62E8; color: white;}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {display: block;}
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {background-color: #7D62E8 ;}
  @media screen and (max-width: 992px){
    .my-navbar{
      padding-left: 30px;
      padding-right: 30px;
      background: #7D62E8;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
     border-bottom-left-radius: 8px;
     border-bottom-right-radius: 8px;
      
    }
  }
.home-nav{
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.home-nav ul li:not(:last-child){
    margin-right: 25px;
}
.dp{
    border: 1px solid #7D62E8;
    width: 50px;
    height: 50px;
    border-radius: 50px;
}
.dp-detail{
    line-height: 6px;
}
.dp-name{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: left;
    color: #7D62E8;
    
}
.dp-email{
    font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 12px;
text-align: left;
color: #7D62E8;
}
.min-profile{
   
    padding: 24px 13px;
    height: 75px;
}
.dp-line{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1.5px solid #ffffff;
}
.dp-nav{
}
.dp-nav-item{
  width: 100%;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #7D62E8;  
}
.my-nav-logo{
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  
}
.row-section{
  background: #7D62E8;
  display: -ms-flexbox;
  /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap;
  /* IE10 */
  flex-wrap: wrap;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 120px;
}
.main {   
  flex: 70%;
 padding-left: 70px;
}
.side {
  flex: 30%;


}
@media screen and (max-width: 1024px){
  .my-navbar.home-nav{
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 0.7rem;
  }
  .row-section{
    padding-left: 30px;
    padding-right: 30px;
  }
  .side{
    flex: 35%;
  }
  .main{
    flex: 65%;
  }
}
@media screen and (min-width: 992px){
  .not-mobile{
    display: none !important;
  }
}
@media screen and (max-width: 992px){
  .mobile{
    display: none;
  }
  .home-nav ul li:not(.mobile){
    margin-right: 12px;
}
.home-nav .my-nav-item{
  padding: 0px;
}
.nav-doc{
  width: 35px;
  height: 35px;
}
.dropbtn{
  padding: 0px;
}
.side{
  display: none;
}
.main{
  width: 100%;
  padding-left: 0px;
}
.row-section{
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 100px;
  background: white;
}
html,body{
  scrollbar-color:    #7D62E8 #ffffff ;   /* scroll thumb & track */
}
::-webkit-scrollbar-track {
  background: #ffffff;
}
::-webkit-scrollbar-thumb {
  background:  #7D62E8;
}
}

@media screen and (max-width: 414px){
  .my-navbar.home-nav{
    padding-left: 15px;
    padding-right: 15px;
  }
}
.medhub{
  font-size: 20px;
  line-height: 24px;
  

}
